<section class="entity-add-edit-page">
    <div class="entity-card-container d-flex flex-column">
        <!-- header part -->
        <div class="entity-header d-flex flex-column justify-content-center">
            <div class="container entity-title">
                <div class="d-flex flex-row justify-content-between align-items-center">
                    <label class="text-black fw-600 fs-20"
                        [innerHtml]="majorDialogData?.header?.title || staticText?.comments?.title">
                    </label>
                    <img class="cursor-pointer" src="./assets/images/common/icons/close_black.svg" alt="close"
                        (click)="close()" />
                </div>
            </div>
        </div>
        <!-- body part -->
        <div class="entity-body">
            <div [innerHTML]="majorDialogData?.template"></div>
            <div class="add-comments-content row-cols-1 p-4">
                @if (majorDialogData?.enableComments) {
                <div class="col comments-input fs-16">
                    <custom-text-area [inputTextAreaClass]="'width-top example-full-width'"
                        [inputTextAreaMaxLength]="1000" [inputTextAreaFormGroup]="commentsForm"
                        [inputTextAreaAppearance]="" inputTextAreaFormControlName="comments"
                        [inputTextAreaRequiredStatus]="true" [inputTextAreaPlaceholderLabel]="staticText?.comments?.Add"
                        [inputTextAreaMinRowSize]="3"
                        [inputTextAreaErrorLabel]="staticText?.comments?.title + ' is Required'">
                    </custom-text-area>
                </div>
                }
                @if (majorDialogData?.enableAttachment) {
                @if (majorDialogData?.multiFileUpload) {
                <div class="col py-4">
                    <app-custom-multi-fileupload [inputFileFormGroup]="commentsForm" [disableFileUpload]="disableForm"
                        [inputFileFormControlName]="multi_attachments"
                        [inputFileAcceptExtenstions]="'.jpg, .png, .doc, .ppt, .pdf'"
                        [inputFileLabel]="staticText?.complaint_management?.management?.add?.add_input_5"
                        inputFilePlaceholderLabel="staticText?.complaint_management?.management?.add?.add_input_5"
                        [assignFileLink]="filePath" [fileName]="showAttachment" [customDownloadDelete]="true"
                        (inputFileOnSelect)="multiSelectFile($event, commentsForm, multi_attachments)">
                    </app-custom-multi-fileupload>
                </div>
                }@else {
                <div class="col py-4">
                    <custom-file-attachment [inputFileFormGroup]="commentsForm" inputFileFormControlName="attachment"
                        [inputFileAcceptExtenstions]="'.jpg, .png, .doc, .ppt, .pdf'"
                        [inputFileLabel]="staticText?.complaint_management?.management?.add?.add_input_5"
                        [inputFileUploadLabel]="staticText?.complaint_management?.management?.add?.add_input_6"
                        [assignFileLink]="filePath" [fileName]="showAttachment" [customDownloadDelete]="true"
                        (inputFileOnSelect)="selectFile($event)">
                    </custom-file-attachment>
                </div>
                }
                }
            </div>
        </div>
        <!-- footer part -->
        <div class="major-footer">
            
            <div class="major-modal-actions  cursor-pointer p-3">
                <div class="major-modal-close-button px-2">
                    <button class="btn_default_user" mat-stroked-button [innerHtml]="
                  majorDialogData?.footer?.cancelBtnTxt || staticText?.common?.cancel_button
                " (click)="close()"></button>
                </div>
                <div class="major-modal-event-button px-2">
                    <button mat-raised-button class="btn_login_user" [ngStyle]="majorDialogData?.footer?.submitBtnStyle"
                        (click)="addComments()">
                        <div>
                            <span
                                [innerHtml]="majorDialogData?.footer?.submitBtnTxt || staticText?.comments?.add_btn"></span>
                            
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>