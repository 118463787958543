import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MaterialModule } from '../../material/material.module';
import { SharedModule } from '../../shared.module';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormValidatorService } from '../../services/form-validator/form-validator.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { ApiMethod, Endpoints } from 'src/app/core/services/utils/constants';
import { HttpService } from 'src/app/core/services/http/http.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-add-comments',
  standalone: true,
  imports: [
    SharedModule,
    MaterialModule,
    CommonModule
  ],
  templateUrl: './add-comments.component.html',
  styleUrl: './add-comments.component.scss'
})
export class AddCommentsComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  commentsForm: FormGroup | any;
  filePath!: string;
  showAttachment: any;
  attachements: any;

  constructor(
    private _formValidator: FormValidatorService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private _loader: LoaderService,
    private _http: HttpService,
    public _createDialogRef: MatDialogRef<AddCommentsComponent>,
    @Inject(MAT_DIALOG_DATA) public majorDialogData: any
  ) { }

  ngOnInit(): void {
    this.commentsSectionForm()
  }

  close() {
    this._createDialogRef.close(false);
  }

  selectFile(ev: any) {
    if (ev) {
      this.attachements = ev[0];
      console.log(this.attachements);
      
    }
  }

  multiSelectFile(ev: any) {
    if (ev && ev.length > 0) {
      // Initialize attachments array if not already initialized
      this.attachements = [];
  
      // Loop through the selected files and add them to the attachments array
      for (const item of ev) {
        this.attachements.push(item);
      }
      
  
      // Log the selected attachments for verification
      console.log(this.attachements);
    }
  }
  addComments() {
    if (this.commentsForm.invalid && this.majorDialogData.enableComments) {
      this.commentsForm.markAllAsTouched();
      return;
    }
    let formData = new FormData();
    const payload = {
      "message": this.commentsForm.value.comments,
      "id": parseInt(this.majorDialogData?.id),
      "attachements" : this.attachements ?? ''
    };
    this._createDialogRef.close(payload);
  }

  commentsSectionForm() {
    this.commentsForm = this.formBuilder.group({
      comments: ['', [this._formValidator.requiredValidationCheck(''), this._formValidator.minMaxLengthCheck(1, 1000)]],
      attachment: [''],
      multi_attachments: ['']
    })
  }

}
